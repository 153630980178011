import { isBrowser } from './fragments/isBrowser';

const lighthouseAgentRegex = /Chrome\/\d+\.0\.0\.0 Mobile Safari\/537\.36/;
const pagespeedAgentRegex = /PageSpeed|Lighthouse|HeadlessChrome/;

export function isIOSSafariBrowser() {
  if (!isBrowser) return false;
  const ua = window.navigator.userAgent;
  const iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i);
  const webkit = !!ua.match(/WebKit/i);
  const iOSSafari = iOS && webkit && !ua.match(/CriOS/i);
  // detect standalone: https://stackoverflow.com/a/40932368/4053349
  const inBrowser = !(navigator as any).standalone;
  return iOSSafari && inBrowser;
}

export function isAndroidBrowser() {
  if (!isBrowser) return false;
  const ua = window.navigator.userAgent.toLowerCase();
  const isAndroid = ua.indexOf('android') > -1;
  const isInWebAppChrome = window.matchMedia('(display-mode: browser)').matches;
  return isAndroid && isInWebAppChrome;
}

export function isLighthouseAgent() {
  if (!isBrowser) return false;
  const ua = window.navigator.userAgent;
  return lighthouseAgentRegex.test(ua);
}

export function isPageSpeedOrLighthouseAgent() {
  if (!isBrowser) return false;
  const ua = window.navigator.userAgent;
  return pagespeedAgentRegex.test(ua) || lighthouseAgentRegex.test(ua);
}
